<template>
  <div>
    <transition-group v-if="teasers.length" tag="section" name="slide" class="grid grid-cols-6 gap-x-20 md:gap-y-60 mb-40">
      <template v-for="(teaser, index) in teasers" class="px-10">
        <TeaserAside3 v-if="!teaser.ad" :teaser="teaser" :lazy-img="index < 6 ? 'eager' : 'lazy'" :key="`loaded${teaser.id}`" class="col-span-6 lg:col-span-2" />
        <template v-else>
          <AdWrapper class="mb-40 col-span-6" :class="{ 'mt-40': teaser.id === 2 }" :ad="{ slotId: `mob_intext_ad_${teaser.id}` }" :key="`mob_ad${teaser.id}`" grey-background />
          <AdWrapper class="mb-40 col-span-6" :class="{ 'mt-40': teaser.id === 2 }" :ad="{ slotId: `dsk_intext_ad_${teaser.id}` }" :key="`dsk_ad${teaser.id}`" grey-background />
        </template>
      </template>
    </transition-group>
    <LoadMoreButton v-cloak v-if="moreTeasersToShow" class="mt-35" @loadMoreClicked="loadMore" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  apiUrl: {
    type: String,
    required: true,
  },
  totalCount: {
    type: Number,
  },
  initialCount: {
    type: Number,
    required: true,
  },
  requestInitial: {
    type: Boolean,
    default: false,
  },
  ads: {
    type: Boolean,
    default: true,
  },
  displayDate: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['resultsCount']);

const apiUrlRef = toRef(props, 'apiUrl');

/**
* Variable storing page nr on load, will increase on loadMore()
* Initial page set to -1 as pages start at 0.
*
* @type number
*/
let page: number = -1;

/**
* Array of teasers to display.
*/
const teasers = ref<null | any>([]);

/**
* Allow for an override of the props.totalCount by making a ref.
*/
const totalComputed = ref(props.totalCount);

/**
* Determine if there are more teasers to load
*
* @return Boolean
*/
const moreTeasersToShow = computed(() => {
  if (!totalComputed.value) {
    return false;
  }

  return totalComputed.value > props.initialCount + teasers.value.length;
});

let pending = ref(false);
const loadMore = async () => {
  pending.value = true;

  page += 1;

  const { data }: { data: any } = await useFetch(`${apiUrlRef.value + (apiUrlRef.value.includes('?') ? '&' : '?')}page=${page}&count=6&offset=${props.initialCount}`, { key: apiUrlRef.value + page });
  const dataRefined = data.value.hasOwnProperty('total') ? data.value.data : data.value;
  let adCounter = 1;

  if (dataRefined) {
    if ((!props.requestInitial || (props.requestInitial && page > 0)) && props.ads) {
      teasers.value.push({ ad: true, id: adCounter++ });
    }

    teasers.value = teasers.value.concat(dataRefined);
    if (data.value.hasOwnProperty('total')) {
      totalComputed.value = data.value.total;
    }
  } else {
    totalComputed.value = 0;
  }

  emit('resultsCount', totalComputed.value);

  pending.value = false;
}

if (props.requestInitial) {
  await loadMore();
}

const watchUrl = watch(apiUrlRef, () => {
  // Reset.
  teasers.value = [];
  page = -1;

  // Reload.
  loadMore();
});

onBeforeUnmount(() => {
  watchUrl
});
</script>

<style lang="css" scoped>
.slide-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transform-origin: left top;
}

.slide-leave-active {
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform-origin: left top;
}

.slide-enter-from,
.slide-leave-to {
  transform: scaleY(0);
  opacity: 0;
}
</style>